function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// for mobile menu

// ======================================================================================================
// FAQ SECTION

function toggleIcon(expandIconPlus, expandIconMinus, isOpen) {
  if (isOpen) {
    expandIconPlus.style.display = 'none';
    expandIconMinus.style.display = 'block';
  } else {
    expandIconPlus.style.display = 'block';
    expandIconMinus.style.display = 'none';
  }
}

function createAccordion(el) {
  let animation = null;
  let isClosing = false;
  let isExpanding = false;
  const summary = el.querySelector('summary');
  const content = el.querySelector('.faq-content');
  const expandIconPlus = summary.querySelector('.icon-tabler-circle-plus');
  const expandIconMinus = summary.querySelector('.icon-tabler-circle-minus');

  function onClick(e) {
    e.preventDefault();
    el.style.overflow = 'hidden';
    if (isClosing || !el.open) {
      open();
    } else if (isExpanding || el.open) {
      shrink();
    }
  }

  function shrink() {
    isClosing = true;
    const startHeight = `${el.offsetHeight}px`;
    const endHeight = `${summary.offsetHeight}px`;
    if (animation) {
      animation.cancel();
    }
    animation = el.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: 'ease-out',
      },
    );
    animation.onfinish = () => {
      toggleIcon(expandIconPlus, expandIconMinus, false);
      onAnimationFinish(false);
    };
    animation.oncancel = () => {
      toggleIcon(expandIconPlus, expandIconMinus, false);
      isClosing = false;
    };
  }

  function open() {
    el.style.height = `${el.offsetHeight}px`;
    el.open = true;
    window.requestAnimationFrame(expand);
  }

  function expand() {
    if (!el || !summary || !content) {
      console.error(
        'Element, summary, or content is not available in the DOM.',
      );
      return;
    }
    isExpanding = true;
    const startHeight = `${el.offsetHeight}px`;
    const endHeight = `${summary.offsetHeight + content.offsetHeight}px`;
    if (animation) {
      animation.cancel();
    }
    animation = el.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 350,
        easing: 'ease-out',
      },
    );
    animation.onfinish = () => {
      toggleIcon(expandIconPlus, expandIconMinus, true);
      onAnimationFinish(true);
    };
    animation.oncancel = () => {
      toggleIcon(expandIconPlus, expandIconMinus, true);
      isExpanding = false;
    };
  }

  function onAnimationFinish(open) {
    el.open = open;
    animation = null;
    isClosing = false;
    isExpanding = false;
    el.style.height = el.style.overflow = '';
  }

  summary.addEventListener('click', onClick);
}

document.querySelectorAll('details').forEach(createAccordion);

function onScroll() {
  const element = document.querySelector('.custom__search');
  const stickyGetStartedElement = document.getElementById('stickyGetStarted');

  if (!element) {
    console.error("Element with class 'custom__search' not found.");
    return;
  }

  if (!stickyGetStartedElement) {
    console.error("Element with ID 'stickyGetStarted' not found.");
    return;
  }
  if (isElementInViewport(element)) {
    document.getElementById('stickyGetStarted').style.display = 'none';
  } else {
    document.getElementById('stickyGetStarted').style.display = 'block';
  }
}

function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan),
      );
    }
  };
}

function isMobile() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

if (isMobile()) {
  window.addEventListener('scroll', throttle(onScroll, 200));
  onScroll();
}

document.addEventListener('DOMContentLoaded', () => {
  const openNavBtn = document.getElementById('openNavBtn');
  const closeBtn = document.querySelector('.closebtn');
  const overlay = document.getElementById('myNav');

  if (openNavBtn) {
    openNavBtn.addEventListener('click', () => {
      overlay.style.width = '100%';
    });
  }

  if (closeBtn) {
    closeBtn.addEventListener('click', () => {
      overlay.style.width = '0%';
    });
  }
});

// ======================================================================================================
// Footer SECTION js

function toggleCollapse(accordion, accordions) {
  accordions.forEach((otherAccordion) => {
    const otherAccordionParent = otherAccordion.parentElement;
    if (otherAccordionParent !== accordion.parentElement) {
      otherAccordionParent.classList.remove('show');
    }
  });

  accordion.parentElement.classList.toggle('show');
}

const accordions = document.querySelectorAll('.accordion-heading');

accordions.forEach((accordion) => {
  accordion.addEventListener('click', () => {
    toggleCollapse(accordion, accordions);
  });
});
